import { useState } from "react";
import { FCC } from "../../utils/types";
import { createStore } from "../utils/createStore";
import { AuthStore } from "./AuthStore";

const Store = createStore<AuthStore>("AuthStore");

export const useAuthStore = Store.useStore.bind(null);

export const AuthStoreProvider: FCC = (props) => {
  const store = useState(() => new AuthStore())[0];
  return <Store.Provider store={store}>{props.children}</Store.Provider>;
};
