import { Suspense, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppBridge, Loading } from "@shopify/app-bridge-react";
import { Redirect } from "@shopify/app-bridge/actions";
import { useQuery } from "@tanstack/react-query";
import { Warning } from "../common/Warning";
import { FCC } from "../../utils/types";
import { verifyOAuth } from "../../stores/auth/AuthStore.services";

export const AppLoader: FCC = ({ children }) => {
  const { search } = useLocation();
  const appBridge = useAppBridge();
  const [isLoading, setIsLoading] = useState(true);

  const [{ shop, embedded }] = useState(() => {
    const params = new URLSearchParams(search);
    const shop = params.get("shop");
    const embedded = params.get("embedded") === "1";

    return {
      shop,
      embedded,
    };
  });

  const { data } = useQuery(
    ["ShopifyOAuthController_authorize", search],
    async () => {
      const params = new URLSearchParams(search);
      return await verifyOAuth(params);
    },
    {
      onSuccess: (d) => {
        setIsLoading(false);
        if (d.permission_grant_needed) {
          const params = new URLSearchParams();
          params.set("client_id", d.client_id!);
          params.set("scope", d.scope!);
          params.set("state", d.nonce!);
          params.set(
            "redirect_uri",
            `https://${window.location.hostname}/auth_callback`
          );
          // TODO: if online token
          // params.set('grant_options[]', 'per-user');
          const authorizeUrl = `https://${shop}/admin/oauth/authorize?${params.toString()}`;

          if (!embedded) {
            window.location.replace(authorizeUrl);
          } else {
            // use app bridge to redirect to the authorize url
            const redirect = Redirect.create(appBridge);
            redirect.dispatch(
              Redirect.Action.REMOTE,
              decodeURIComponent(authorizeUrl)
            );
          }
        }
      },
    }
  );

  if (!shop) {
    return (
      <Warning
        title={"Missing shop query argument"}
        children={
          <>
            Your app can only load if the URL has a <b>shop</b> argument. Please
            ensure that it is set, or access your app using the Partners
            Dashboard <b>Test your app</b> feature
          </>
        }
        status={"critical"}
      />
    );
  }

  if (isLoading) {
    return <Loading />;
  } else {
    if (data?.permission_grant_needed) {
      return (
        <Warning
          title={"Redirecting to permission grant screen"}
          children={<>App permission grant needed, redirecting...please wait</>}
          status={"info"}
        />
      );
    } else {
      return <Suspense>{children}</Suspense>;
    }
  }
};
