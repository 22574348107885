import { useState } from "react";
import { Card, Text, TextContainer } from "@shopify/polaris";
import { Toast } from "@shopify/app-bridge-react";
import { useAuthStore } from "../stores/auth/useAuthStore";
import { useQuery } from "@tanstack/react-query";
import { getProductCount } from "../stores/shopify/ShopifyStore.services";
import { waitFor } from "../stores/utils/waitFor";

export function ProductsCard() {
  const emptyToastProps: { content: string; error?: boolean } = { content: "" };
  const [isLoading, setIsLoading] = useState(true);
  const [toastProps, setToastProps] = useState(emptyToastProps);

  const auth = useAuthStore();

  const {
    data,
    isLoading: isLoadingCount,
  } = useQuery(["ShopifyApiController_countProducts"], async () => {
    const fbToken = await waitFor(() => auth.firebaseToken$);
    return await getProductCount(fbToken);
  }, {
    onSuccess: (d) => {
      setIsLoading(false);
    },
  });

  const toastMarkup = toastProps.content && (
    <Toast {...toastProps} onDismiss={() => setToastProps(emptyToastProps)} />
  );

  return (
    <>
      {toastMarkup}
      <Card
        title="Product Counter"
        sectioned
      >
        <TextContainer spacing="loose">
          <p>
            Sample products are created with a default title and price. You can
            remove them at any time.
          </p>
          <Text variant="headingXl" as="h4">
            TOTAL PRODUCTS
          </Text>
          <Text variant="headingLg" as="h5">
            {isLoadingCount ? "-" : data?.count}
          </Text>
        </TextContainer>
      </Card>
    </>
  );
}
