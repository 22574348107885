import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyATbGdP4qFdHE0hg6PYZRFwy8d_OUNWWSo",
  authDomain: "turing-runner-366512.firebaseapp.com",
  projectId: "turing-runner-366512",
  storageBucket: "turing-runner-366512.appspot.com",
  messagingSenderId: "916757239989",
  appId: "1:916757239989:web:273cbe39a2bb8e30c5c6fc"
};

export const app = firebase.initializeApp(firebaseConfig);

const useEmulator = true;
// const useEmulator = false;

if (useEmulator) {
  const emulatorIPAddress = "localhost";
  (firebase.auth().useEmulator as any)(`http://${emulatorIPAddress}:9099`, {
    disableWarnings: true,
  });
  firebase.firestore().useEmulator(emulatorIPAddress, 8080);
  // firebase.database().useEmulator(emulatorIPAddress, 9000);
  // firebase.functions().useEmulator(emulatorIPAddress, 5001);
  // firebase.storage().useEmulator(emulatorIPAddress, 9199);
}

export const auth = firebase.auth();
export const authModule = firebase.auth;
export const firestore = firebase.firestore();
firestore.settings({ ignoreUndefinedProperties: true, merge: true });
export const firestoreModule = firebase.firestore;
// export const database = firebase.database();
// export const databaseModule = firebase.database;
