import zipObj from "lodash/zipObject";

export type UnboxPromise<T> = T extends PromiseLike<infer R> ? R : never;

export function defer<T = void>(): defer.Deferred<T> {
  const result: defer.Deferred<T> = {
    isPending: true,
  } as any;
  result.promise = new Promise<T>((_resolve, _reject) => {
    result.resolve = (v) => {
      result.isPending = false;
      _resolve(v);
    };
    result.reject = (v) => {
      result.isPending = false;
      _reject(v);
    };
  });
  return result;
}
export namespace defer {
  export interface Deferred<T> {
    isPending: boolean;
    promise: Promise<T>;
    resolve: (value: T) => void;
    reject: (err: any) => void;
  }
}

export function sleep(ms: number): Promise<void> {
  return new Promise((r) => setTimeout(r, ms));
}

export async function props<I extends Record<string, Promise<any>>>(
  inputs: I
): Promise<{ [K in keyof I]: Awaited<I[K]> }> {
  const res = await Promise.all(Object.values(inputs));
  return zipObj(Object.keys(inputs), res) as any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isPromiseLike(input: any): input is PromiseLike<any> {
  return (
    input != null &&
    typeof input === "object" &&
    typeof input.then === "function"
  );
}

export function isNotNull<T>(input: T | undefined | null): input is T {
  return input != null;
}
