import { Loading, useAppBridge } from "@shopify/app-bridge-react";
import { useQuery } from "@tanstack/react-query";
import { Suspense, useState } from "react";
import { loginWithShopifyToken } from "../../stores/auth/AuthStore.services";
import { FCC } from "../../utils/types";

export const FirebaseAuth: FCC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const appBridge = useAppBridge();

  useQuery(
    ["loginWithShopifyToken"],
    async () => {
      await loginWithShopifyToken(appBridge);
      return true;
    },
    {
      onSuccess: (d) => {
        setIsLoading(false);
      },
    }
  );

  return <>{isLoading ? <Loading /> : <Suspense>{children}</Suspense>}</>;
};
