import { Card, Page, Layout, TextContainer, Text } from "@shopify/polaris";
import { TitleBar } from "@shopify/app-bridge-react";

export default function Dashboard() {
  return (
    <Page>
      <TitleBar title="Dashboard" />
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <Text variant="heading4xl" as="h1">
              This is the dashboard
            </Text>
            <TextContainer>
              <p>This is the content of the dashboard</p>
            </TextContainer>
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
