import { Banner, Layout, Page } from "@shopify/polaris";


export function Warning(props: {
    title: string;
    status: 'success' | 'info' | 'warning' | 'critical';
    children: React.ReactNode;
}) {
    return (
        <Page narrowWidth>
          <Layout>
            <Layout.Section>
              <div style={{ marginTop: "100px" }}>
                <Banner {...props} />
              </div>
            </Layout.Section>
          </Layout>
        </Page>
      );
}