import { computed, makeObservable } from "mobx";
import { LazyValue } from "../utils/LazyValue/LazyValue";
import { authState$ } from "./AuthStore.services";
import type { User } from "firebase/auth";
import { waitUntilExist$ } from "../utils/waitFor";
import { from } from "rxjs";

export class AuthStore {
  constructor() {
    makeObservable(this);
  }

  private authState = new LazyValue(
    () => null,
    () => authState$
  );

  @computed get loggedInUser$(): User {
    return waitUntilExist$(() => this.authState.value$);
  }

  @computed get isLoggedIn$() {
    return this.authState.value$ != null;
  }

  private firebaseToken = new LazyValue(
    () => [this.loggedInUser$] as const,
    ([user]) => from(user.getIdToken())
  );

  @computed get firebaseToken$(): string {
    return this.firebaseToken.value$;
  }
}
