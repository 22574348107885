import { Loading } from "@shopify/app-bridge-react";
import { redirect, useLocation } from "react-router-dom";
import { Suspense, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Buffer } from "buffer";
import { Warning } from "../common/Warning";
import { handleOAuthCallback } from "../../stores/auth/AuthStore.services";
import { FCC } from "../../utils/types";

export const AuthCallback: FCC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { search, pathname } = useLocation();

  const [{ shop, host, embedded }] = useState(() => {
    const params = new URLSearchParams(search);
    const shop = params.get("shop");
    const host = params.get("host");
    const embedded = params.get("embedded") === "1";

    return {
      shop,
      host,
      embedded,
    };
  });

  const getEmbeddedAppUrl = (host: string, client_id: string): string => {
    const decodedHost = Buffer.from(host, "base64").toString();
    return "https://".concat(decodedHost, "/apps/").concat(client_id);
  };

  const isHandleOAuthCallback = (pathname: string) => {
    return pathname === "/auth_callback";
  };

  useQuery(
    ["ShopifyOAuthController_callback", search],
    async () => {
      const params = new URLSearchParams(search);
      return await handleOAuthCallback(params);
    },
    {
      enabled: isHandleOAuthCallback(pathname),
      onSuccess: (d) => {
        setIsLoading(false);
        if (d.embedded && !embedded) {
          window.location.replace(getEmbeddedAppUrl(host!, d.client_id));
        } else {
          redirect(`/?shop=${shop}&host=${encodeURIComponent(host!)}`);
        }
      },
    }
  );

  if (isHandleOAuthCallback(pathname)) {
    if (isLoading) {
      return <Loading />;
    } else {
      return (
        <Warning
          title={"Redirecting to app"}
          children={
            <>App permission grant completed, redirecting...please wait</>
          }
          status={"success"}
        />
      );
    }
  } else {
    return <Suspense>{children}</Suspense>;
  }
};
