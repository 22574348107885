import { BrowserRouter, Routes, Route } from "react-router-dom";
import { NavigationMenu } from "@shopify/app-bridge-react";

import { AppBridgeProvider, QueryProvider, PolarisProvider } from "./contexts";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/dashboard";
import PageName from "./pages/pagename";
import Homepage from "./pages";
import { AppLoader } from "./components/functional/AppLoader";
import { AuthStoreProvider } from "./stores/auth/useAuthStore";
import { FCC } from "./utils/types";
import { FC, Suspense } from "react";
import { AuthCallback } from "./components/functional/AuthCallback";
import { FirebaseAuth } from "./components/functional/FirebaseAuth";

const StoreProviders: FCC = ({ children }) => (
  <AuthStoreProvider>
    <Suspense>{children}</Suspense>
  </AuthStoreProvider>
);

const ShopifyAppProviders: FCC = ({ children }) => (
  <PolarisProvider>
    <AppBridgeProvider>
      <Suspense>{children}</Suspense>
    </AppBridgeProvider>
  </PolarisProvider>
);

const AppPreloaders: FCC = ({ children }) => (
  <AuthCallback>
    <Suspense>{children}</Suspense>
  </AuthCallback>
);

const App: FC = () => {
  return (
    <BrowserRouter>
      <ShopifyAppProviders>
        <StoreProviders>
          <QueryProvider>
            <AppPreloaders>
              <AppLoader>
                <FirebaseAuth>
                  <Suspense>
                    {/* <NavigationMenu
                navigationLinks={[
                  {
                    label: "Page name",
                    destination: "/pagename",
                  },
                ]}
              /> */}
                    <Routes>
                      <Route path="/" element={<Homepage />} />
                      <Route path="dashboard" element={<Dashboard />} />
                      <Route path="pagename" element={<PageName />} />
                      <Route path="auth_callback" element={<AuthCallback />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </Suspense>
                </FirebaseAuth>
              </AppLoader>
            </AppPreloaders>
          </QueryProvider>
        </StoreProviders>
      </ShopifyAppProviders>
    </BrowserRouter>
  );
};

export default App;
