import type { User } from "firebase/auth";
import { defer, Observable, shareReplay } from "rxjs";
import { sendPublicRequest } from "../../generated/api";
import { auth } from "../firebase/firebaseServices";
import { AppBridgeState, ClientApplication } from "@shopify/app-bridge";
import { getSessionToken } from "@shopify/app-bridge-utils";

export const authState$: Observable<User | null> = defer(
  () =>
    new Observable<User | null>(function (subscriber) {
      const unsubscribe = auth.onAuthStateChanged({
        next: subscriber.next.bind(subscriber),
        error: subscriber.error.bind(subscriber),
        complete: subscriber.complete.bind(subscriber),
      });
      return { unsubscribe };
    })
).pipe(shareReplay(1));

export const redeemFirebaseToken = async (
  shopifySessionToken: string
): Promise<string> => {
  const result = await sendPublicRequest("AuthController_adminAuth", {
    header: {
      Authorization: `Bearer ${shopifySessionToken}`,
    },
  });
  return result.data["custom_token"];
};

export async function loginWithShopifyToken(
  app: ClientApplication<AppBridgeState>
): Promise<void> {
  const sessionToken = await getSessionToken(app);
  const firebaseToken = await redeemFirebaseToken(sessionToken);
  await auth.signInWithCustomToken(firebaseToken);
}

export async function logout(): Promise<void> {
  await auth.signOut();
}

export async function verifyOAuth(queryParams: URLSearchParams) {
 const result = await sendPublicRequest("ShopifyOAuthController_authorize", {
    query: Object.fromEntries(queryParams),
  });

  return result.data;
}

export async function handleOAuthCallback(queryParams: URLSearchParams) {
  const result = await sendPublicRequest("ShopifyOAuthController_callback", {
    query: Object.fromEntries(queryParams),
  });

  return result.data;
}
